<template>
  <div id="app">
    <div v-if="!isMobile">
      <div class="header">
        <div @click="toPage('/')" class="header-img">
          <img src="../static/image/logo.png" />
        </div>
        <div class="header-center">
          <div v-for="item in pageList" :key="item.id" :style="{ background: nav_id == item.id ? '#FBFBFB' : '#fff' }">
            <div :class="{ 'header-text-border': nav_id == item.id }" class="header-text" @click="nav_click(item.id)">
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="header-phone">
          <img src="../static/image/home_phone.png" />
          <div class="header-phone-num">400-185-3168</div>
        </div>
        <div class="header-download" @click="toPage('/download')">
          APP下载
        </div>
      </div>
      <router-view />
      <div class="foot">
        <div class="foot_top_box">
          <div class="foot_top_box_content">
            <div class="foot_center_box">
              <ul v-for="item in list" :key="item.id">
                <div class="foot_center_box_head">
                  {{ item.head }}
                </div>
                <li @click="toPage(item.content.path, 0)">
                  {{ item.content.content1 }}
                </li>
                <li @click="toPage(item.content.path, 1)">
                  {{ item.content.content2 }}
                </li>
                <li v-if="item.content.content3 == '下载中心'" @click="toPage('/download', 2)">
                  {{ item.content.content3 }}
                </li>
                <li v-else @click="toPage(item.content.path, 2)">
                  {{ item.content.content3 }}
                </li>
                <li @click="toPage(item.content.path, 4)">
                  {{ item.content.content4 }}
                </li>
                <li @click="toPage(item.content.path, 4)">
                  {{ item.content.content5 }}
                </li>
              </ul>
            </div>
            <div class="foot_center_left_box">
              <div>
                <img src="../static/image/foot_logo.png" width="92px" />
              </div>
            </div>
          </div>
        </div>
        <div class="foot_bottom_box">
          <div>
            <a target="_blank" href="https://beian.miit.gov.cn" style="
                      display: inline-block;
                      text-decoration: none;
                      height: 20px;
                      line-height: 20px;
                    "><img src="../static/image/beian.png" style="float: left" />
              <p style="
                        float: left;
                        height: 20px;
                        line-height: 20px;
                        margin: 0px 0px 0px 5px;
                        color: rgba(255, 255, 255, 0.2);
                      ">
                 蜀ICP备2023015908号-1 &nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302001255"
                  style="
                      display: inline-block;
                      text-decoration: none;
                      height: 20px;
                      line-height: 20px;
                    ">
                  <p style="
                        float: left;
                        height: 20px;
                        line-height: 20px;
                        margin: 0px 0px 0px 5px;
                        color: rgba(255, 255, 255, 0.2);
                      ">
                    浙公网安备33060302001255号
                  </p>
                </a>
            </a>
            <p
              style="color: rgba(255, 255, 255, 0.2); font-size: 12px; text-align: center;display: inline-block;margin: 0px 0px 0px 16px;vertical-align: super;">
              四川中峪拍卖交易服务有限责任公司 版权所有
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile">
      <div style="height: 20px;"></div>
      <div class="m-head">
        <img src="../static/image/mobile/m_logo.png" width="108px" />
        <img src="../static/image/mobile/m_home_menu.png" width="16px" height="13px" @click="showPopup" />
      </div>
      <router-view />
      <div class="m-foot">
        <div class="m-foot-address">
          <div class="m-foot-address-bg">
            <div class="m-foot-address-title">联系方式</div>
            <div class="m-foot-address-tel">客服电话：400-185-3168</div>
            <div class="m-foot-address-name">
              公司名称：四川中峪拍卖交易服务有限责任公司
            </div>
            <div class="m-foot-address-addr">
              地址：浙江绍兴柯桥区IFC国际金融中心裙楼2F
            </div>
          </div>
          <img src="../static/image/foot_logo.png" width="56px" height="75px" />
        </div>
        <div class="m-foot-box">
          <p
            style="color: rgba(255, 255, 255, 0.2); font-size: 8px; text-align: center;display: inline-block;margin: 0px 0px 0px 16px;vertical-align: super;">
            四川中峪拍卖交易服务有限责任公司 版权所有
          </p><br />
          <a target="_blank" href="https://beian.miit.gov.cn" style="
                    display: inline-block;
                    text-decoration: none;
                    height: 20px;
                    line-height: 20px;
                  "><img src="../static/image/beian.png" style="float: left" />
            <p style="
                      float: left;
                      height: 20px;
                      line-height: 20px;
                      margin: 0px 0px 0px 5px;
                      color: rgba(255, 255, 255, 0.2);
                    ">
              浙ICP备2020034128号 &nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </a><a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302001196"
            style="
                    display: inline-block;
                    text-decoration: none;
                    height: 20px;
                    line-height: 20px;
                  ">
            <p style="
                      float: left;
                      height: 20px;
                      line-height: 20px;
                      margin: 0px 0px 0px 5px;
                      color: rgba(255, 255, 255, 0.2);
                    ">
              浙公网安备33060302001255
            </p>
          </a>
        </div>
      </div>
      <van-popup v-model="mshowtop" position="top">
        <div class="m-head m-head-margin">
          <img src="../static/image/mobile/m_logo.png" width="108px" />
          <img src="../static/image/mobile/m_home_menu.png" width="16px" height="13px" @click="showPopup" />
        </div>
        <div class="m-pageList" v-for="list in pageList" :key="list.id">
          <div class="showNav" :class="nav_id == list.id ? 'm-isActive' : ''">
            <div @click="mobileJunp(list.id)">
              {{ list.text }}
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      nav_id: "0",
      pageList: [
        {
          id: "1",
          text: "网站首页",
        },
        {
          id: "2",
          text: "公司介绍",
        },
        {
          id: "3",
          text: "信息资讯",
        },
        {
          id: "4",
          text: "中峪商贸",
        },
        {
          id: "5",
          text: "下载中心",
        },
      ],
      list: [
        {
          head: "关于我们",
          content: {
            path: "introduction",
            content1: "公司介绍",
            content2: "企业文化",
            content3: "下载中心",
          },
        },
        {
          head: "信息咨询",
          content: {
            path: "newsList",
            content1: "信息披露",
            content2: "新闻资讯",
            content3: "通知公告",
            content5: "购销指南",
          },
        },
        {
          head: "联系方式",
          content: {
            content1: "客服电话：400-185-3168",
            content2: "公司名称：四川中峪拍卖交易服务有限责任公司",
            content3: "地址：浙江绍兴柯桥区IFC国际金融中心裙楼2F",
          },
        },
      ],
      isMobile: false,
      mshowtop: false,
    };
  },
  mounted() {
    this.userAgent = navigator.userAgent;
    // 定义手机设备的关键词
    let mobileKeywords = ["Mobile", "Android", "iPhone", "Windows Phone"];
    this.isMobile = false;
    for (let i = 0; i < mobileKeywords.length; i++) {
      if (this.userAgent.indexOf(mobileKeywords[i]) > -1) {
        this.isMobile = false;
        break;
      }
    }
    if (window.innerWidth < 510) {
      this.isMobile = false;
    }
  },
  methods: {
    nav_click(x) {
      this.nav_id = x;
      switch (x) {
        case "1":
          this.$router.push("/");
          break;
        case "2":
          this.$router.push("/introduction");
          break;
        case "3":
          this.$router.push("/newsList");
          break;
        case "4":
          this.$router.push("/business");
          break;
        case "5":
          this.$router.push("/download");
          break;
      }
    },
    toPage(path, index) {
      if (path) {
        this.$router.push({ path, query: { index } });
      }
    },
    showPopup() {
      this.mshowtop = true;
    },
    mobileJunp(x) {
      this.nav_id = x;
      switch (x) {
        case "1":
          this.$router.push("/m");
          break;
        case "2":
          this.$router.push("/mintroduction");
          break;
        case "3":
          this.$router.push("/mnewsList");
          break;
        case "4":
          this.$router.push("/mbusiness");
          break;
        case "5":
          this.$router.push("/mdownload");
          break;
      }
      this.mshowtop = false;
    },
  },
  watch: {
    $route(to) {
      let path = to.path;
      if (path[path.length - 1] == '/') {
        path = path.substring(0, path.length - 1)
      }
      if (this.isMobile) {
        switch (path) {
          case "/m":
            this.nav_id = 1;
            break;
          case "/mintroduction":
            this.nav_id = 2;
            break;
          case "/mnewsList":
            this.nav_id = 3;
            break;
          case "/mbusiness":
            this.nav_id = 4;
            break;
          case "/mdownload":
            this.nav_id = 5;
            break;
          case "/mbusinessDetail":
            this.nav_id = 4;
            break;
          case "/mnewsListDetail":
            this.nav_id = 3;
            break;
        }
      } else {
        switch (path) {
          case "":
            this.nav_id = 1;
            break;
          case "/":
            this.nav_id = 1;
            break;
          case "/introduction":
            this.nav_id = 2;
            break;
          case "/newsList":
            this.nav_id = 3;
            break;
          case "/business":
            this.nav_id = 4;
            break;
          case "/download":
            this.nav_id = 5;
            break;
          case "/businessDetail":
            this.nav_id = 4;
            break;
          case "/newsListDetail":
            this.nav_id = 3;
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.m-head-margin {
  margin: 20px 0 25px 0;
}

.header {
  width: 1240px;
  margin: 0 auto;
  display: flex;
  height: 80px;
  align-items: center;

  .header-img {
    cursor: pointer;
    height: 42px;

    img {
      height: 42px;
    }
  }

  .header-center {
    display: flex;
    height: 80px;
    line-height: 80px;
    margin-left: 110px;
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 控制文本溢出时隐藏超出部分 */
    text-overflow: ellipsis;

    /* 当文本溢出时显示省略号 */
    div {
      max-width: 140px;
    }

    .header-text {
      height: 90%;
      margin: 0 30px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      color: #565759;
    }

    .header-text-border {
      font-weight: 600;
      font-size: 16px;
      color: #242121;
      border-bottom: 4px solid #9e1307;
    }
  }

  .header-phone {
    margin-left: 134px;

    .header-phone-num {
      margin-left: 10px;
      color: #565759;
      font-size: 14px;
      display: inline-block;
    }
  }

  .header-download {
    width: 104px;
    height: 40px;
    background: #9e1307;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 16px;
    cursor: pointer;
  }
}

.foot {
  width: 100%;
  min-width: 1240px;
  background-color: #8e1c12;
  border-top: 18px solid #9e1307;
  padding-top: 16px;

  .foot_top_box {
    width: 1240px;
    min-width: 720px;
    margin: 0 auto;
    padding-bottom: 90px;

    .foot_top_box_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 20px;

      .foot_center_box {
        height: 168px;
        display: flex;
        margin-top: 61px;

        .foot_center_left_box {
          margin-top: 61px;
          width: 220px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            color: rgba(255, 255, 255, 0.56);
            cursor: pointer;
            font-size: 12px;
            line-height: 32px;
            margin: 0 140px 0 0;
          }
        }
      }

      .foot_center_box_head {
        height: 26px;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }

  .foot_bottom_box {
    background: #2c2929;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 80px;
  }
}

.m-head {
  width: calc(100vw - 48px);
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
}

.m-foot {
  border-top: 9px solid #9e1307;

  .m-foot-address {
    display: flex;
    justify-content: space-between;
    background-color: #8e1c12;
    padding: 30px;

    .m-foot-address-bg {

      font-size: 8px;
      color: #ffffff;
      line-height: 20px;
    }

    .m-foot-address-title {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .m-foot-box {
    padding: 10px 0;
    text-align: center;
    background-color: #2C2929;
    font-size: 8px;
    color: #fff;
  }
}

.m-pageList {
  color: #252525;
  font-size: 16px;
  padding: 20px 25px;
}

.m-isActive {
  color: #abaeb0;
}
</style>
